<template>
  <div class="pa-0">
    <div class="d-flex justify-space-between">
      <h3>{{ title }}</h3>
      <div class="main_icons">
        <div class="icons" @click.prevent="$refs.carousel.prev()">
          <a class="arrow_left"></a>
          <a class="arrow_c"></a>
        </div>
        <div @click.prevent="$refs.carousel.next()" class="icons i_right">
          <a class="arrow_left"></a>
          <a class="arrow_c"></a>
        </div>
      </div>
    </div>
    <div class="slider__container">
      <!-- :style="
        negativeMargin && $vuetify.breakpoint.mdAndUp
          ? 'margin-right: calc((100vw - 983px) / 2 * -1); margin-left: -12px;'
          : 'margin: 0 -12px;'
      " -->
      <VueSlickCarousel
        ref="carousel"
        v-bind="settings"
        v-if="items.length"
        :slidesToShow="slidesCount"
      >
        <div
          class="loop pa-2 cursor-pointer"
          v-for="item in items"
          :key="item.id"
          @click="$emit('goToProduct', item.product.slug)"
        >
          <div class="loop__inner" :class="{ 'loop__product-inner': product }">
            <div
              class="inner-image"
              :style="`background-image: url('${
                api_url + item?.product?.photo
              }'); background-size: cover;`"
              :class="{ bordered: product }"
            >
              <span class="inner-image--sale">Sale</span>
              <button
                class="add_to_cart"
                @click="$emit('goToProduct', item.product.slug)"
              >
                ADD TO CART
              </button>
            </div>
            <!-- <div v-if="!product" class="inner-text bordered border-top-none">
              
            </div> -->
            <div class="p_text bordered border-top-none">
              <p>{{ item?.product?.name?.ru }}</p>
              <div class="price">
                <div class="old_price">
                  <span>{{ item?.currency?.grapheme }}</span>
                  <span>{{ item?.price?.view }}</span>
                </div>
                <div class="new_price">
                  <span>{{ item?.currency?.grapheme }}</span>
                  <span>{{
                    Number(
                      +item?.price?.view - +item?.price?.discount.view
                    ).toFixed(2)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
      <div v-else> Data are still not there </div>
    </div>
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'CarouselComponent',
  components: {
    VueSlickCarousel,
  },
  props: {
    title: {
      type: String,
      default: 'custom title',
    },
    items: {
      type: Array,
      default: () => [],
    },
    slidesToShow: {
      type: Number,
      default: 4,
    },
    negativeMargin: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Boolean,
      default: false,
    },
    api_url: {
      type: String,
    },
  },
  data() {
    return {
      settings: {
        dots: false,
        focusOnSelect: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        touchThreshold: 0,
        // variableWidth: true,
      },
    }
  },
  computed: {
    slidesCount() {
      if (this.items?.length <= 4) {
        return +this.items.length
      } else return 4
    },
  },
  watch: {
    items() {
      this.$refs.slider[0].slick.refresh()
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  min-width: 0;
  min-height: 0;
}
.slider__container {
  margin-left: -8px;
  margin-right: -7px;
  display: flex;
  flex-wrap: nowrap;
}

.slick-slide {
  width: 250px !important;
}

.loop {
  height: 307px;
  &__product-inner {
    cursor: pointer;
  }
  &__product-inner:hover {
    .inner-image:before {
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 307px;
      background: #41414133;
      position: absolute;
      z-index: 1;
      transition: 0.5s;
      -webkit-transition: 0.5s;
    }
    .add_to_cart {
      opacity: 1;
    }
  }
  &__inner {
    position: relative;
    .inner-image {
      height: 307px;
      &--sale {
        position: absolute;
        font-size: 0.75rem;
        font-weight: normal;
        color: #766c64;
        background: #e8d36a;
        border: 1px solid #e8d36a;
        border-radius: 2px;
        text-align: center;
        top: 7%;
        left: 8%;
        padding: 0.5% 7%;
        z-index: 1;
      }
      position: relative;
      padding-top: 100%;
      background-size: cover;
    }
    .inner-text {
      padding: 6% 0;
      text-align: center;
    }
  }
}
.add_to_cart {
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  border: none;
  border-radius: 2%;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  width: 74%;
  height: 14%;
  opacity: 0;
}
.main_icons {
  width: unset;
}

.p_text {
  color: #666666;
  text-align: center;
  padding: 3% 0;
}
.p_text p {
  font-size: 0.875rem;
  color: #666666;
  font-weight: normal;
  margin-bottom: 0;
  text-transform: capitalize;
}
.p_text .price {
  display: flex;
  padding: 0 20%;
  justify-content: center;
  @media (max-width: 523px) {
    padding: 0 10%;
  }
}
.p_text .price .old_price {
  font-size: 1.125rem;
  font-weight: normal;
  text-decoration-line: line-through;
  padding-right: 10%;
}
.p_text .price .new_price {
  font-size: 1.125rem;
  font-weight: bold;
}

h3 {
  font-size: 1.125rem;
  color: #423f3d;
  font-weight: 500;
  text-transform: capitalize;
  width: 94%;
}
.border-top {
  border-top: 1px solid #f2f2f2;
}
.bordered {
  border: 1px solid #f2f2f2;
}
.border-top-none {
  border-top: none;
}
</style>
