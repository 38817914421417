<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="dialog" max-width="700" @click:outside="$emit('close')">
      <v-card title="Video">
        <div class="center" v-html="video"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray" variant="outlined" @click="$emit('close')"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    video: String,
    dialog: Boolean,
  },
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}
</style>
